//* Codekit's special way of adding js files /*

import $ from 'jquery';

//Use document.querySelectorAll to find the element which currently have the active class, then you can remove that class.


function togglemenu() {
    document.body.classList.toggle("open-menu");
}

////////////////////////////////////////
var colors = new Array(
    [11, 134, 206], [26, 224, 203], [206, 61, 139], [34, 163, 153], [154, 69, 154], [211, 173, 89]);

var step = 0;
//color table indices for: 
// current color left
// next color left
// current color right
// next color right
var colorIndices = [0, 1, 2, 3];

//transition speed
var gradientSpeed = .001;

function updateGradient() {

    var c0_0 = colors[colorIndices[0]];
    var c0_1 = colors[colorIndices[1]];
    var c1_0 = colors[colorIndices[2]];
    var c1_1 = colors[colorIndices[3]];

    var istep = 1 - step;
    var r1 = Math.round(istep * c0_0[0] + step * c0_1[0]);
    var g1 = Math.round(istep * c0_0[1] + step * c0_1[1]);
    var b1 = Math.round(istep * c0_0[2] + step * c0_1[2]);
    var color1 = "#" + ((r1 << 16) | (g1 << 8) | b1).toString(16);

    var r2 = Math.round(istep * c1_0[0] + step * c1_1[0]);
    var g2 = Math.round(istep * c1_0[1] + step * c1_1[1]);
    var b2 = Math.round(istep * c1_0[2] + step * c1_1[2]);
    var color2 = "#" + ((r2 << 16) | (g2 << 8) | b2).toString(16);

    document.getElementById("gradient").style.background = "radial-gradient(circle at center, " + color1 + ", " + color2 + ")";
    document.getElementById("gradient-cover").style.opacity = "0";

    step += gradientSpeed;
    if (step >= 1) {
        step %= 1;
        colorIndices[0] = colorIndices[1];
        colorIndices[2] = colorIndices[3];

        //pick two new target color indices
        //do not pick the same as the current one
        colorIndices[1] = (colorIndices[1] + Math.floor(1 + Math.random() * (colors.length - 1))) % colors.length;
        colorIndices[3] = (colorIndices[3] + Math.floor(1 + Math.random() * (colors.length - 1))) % colors.length;

    }
}

window.requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame;

$(function() {



    /*
    anime({
        targets: '#loader',
        rotate: 360,
        easing: 'linear',
        duration: 3000,
        loop: true
    });*/


    var region = 'all';
    var therapy = 'all';

    // Drop down category menu

    $('main').removeClass('opacity-0');

    function myFunction() {
        alert('hey');
    }


    document.querySelectorAll('.tag').forEach(el => {
        el.addEventListener('click', function(e) {

            var myClass = 't_' + this.getAttribute('title');
            document.querySelectorAll('.healer-item').forEach(el => {
                console.log(myClass);
                if (el.classList.contains(myClass)) {
                    el.classList.remove('hidden');
                } else {
                    el.classList.add('hidden');
                }
            })


        });
    });





    function filterTags(region = 'all', therapy = 'all') {



        //if both year and region's value = all then show all posts
        //if region shows all but year contains a value add class hide






        if (region == 'all' && therapy == 'all') {
            $('#listDisplay').fadeOut('slow', function() {
                $('.selector').css('display', 'grid ');
                $(this).fadeIn('slow');
            });
        }
        if (region == 'all' && therapy != 'all') {
            //if both therapy and region are selected do this
            $('#listDisplay').fadeOut('slow', function() {
                $('.selector').css('display', 'none');
                $('.t_' + therapy).css('display', 'grid');

                $(this).fadeIn('slow');
            })
        }
        if (region != 'all' && therapy == 'all') {

            $('#listDisplay').fadeOut('slow', function() {
                $('.selector').css('display', 'none');
                $('div[data-region="' + region + '"]').css('display', 'grid');
                $(this).fadeIn('slow');
            })
        }
        if (region != 'all' && therapy != 'all') {
            //if both therapy and region are selected do this
            $('#listDisplay').fadeOut('slow', function() {
                $('.selector').css('display', 'none');
                $('.t_' + therapy + '[data-region="' + region + '"]').css('display', 'grid');
                $(this).fadeIn('slow');
            })
        }


        console.log('total number' + document.getElementById('listDisplay').getElementsByTagName('li').length);
        console.log($('.healer-item').css('display', 'none').length);


    }

    $("#filters-therapy")
        .change(function() {
            therapy = $(this).val();
            filterTags(region, therapy);

        })


    $("#filters-region")
        .change(function() {
            region = $(this).val();
            console.log(region);
            filterTags(region, therapy)
        })
        //////

    /*
        const boxes = document.querySelectorAll(".box");
        const maxAngle = 0.5;
        const speed = 0.01;

        function animateBox(box, angle, direction) {
            angle += direction * speed;
            if (angle > maxAngle || angle < -maxAngle) {
                direction *= -1;
            }
            box.style.transform = `rotate(${angle}deg)`;
            box.style.webkitTransform = `rotate(${angle}deg)`;
            requestAnimationFrame(() => animateBox(box, angle, direction));
        }

        boxes.forEach((box, index) => {
            let angle = 0;
            let direction = index % 2 === 0 ? 1 : -1;
            animateBox(box, angle, direction);
        });*/
    if (document.body.classList.contains('home')) {
        updateGradient();
        setInterval(updateGradient, 10);
    }

    const menuToggle = document.querySelector('.menu-toggle');
    menuToggle.addEventListener("click", function() {
        togglemenu();
    })
});